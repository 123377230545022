import React, { useEffect } from "react";
import { IoClose, IoNotifications } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./.module.scss";
import { Logo } from "../../../components";
import { Badge } from "@mui/material";
import { useApi } from "../../../hooks/useApi";

const MainSidebar = ({ onClose, show, setShow }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onRequest: onRequestNotVote, data: dataNotVote } = useApi("/api/vote_notification", "get");

  useEffect(() => {
    onRequestNotVote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.sidebar__content}>
      <button className={styles.close__btn} onClick={onClose}>
        <IoClose />
      </button>
      <div className={styles.logo}>
        <Logo style={{ width: "120px", margin: "50px auto" }} noSubtitle />
      </div>
      <nav>
        <ul className={styles.list}>
          <li onClick={() => {
            onClose();
            setShow(false);
          }}>
            <Link to="/">{t("mainPage")}</Link>
          </li>
          <li onClick={onClose}>
            <Link to="/profile">{t("profile")}</Link>
          </li>
          <li onClick={onClose}>
            <Link to="/archives">{t("familyArchive")}</Link>
          </li>
          <li onClick={onClose}>
            <Link to="/inquiries">{t("Inquiries")}</Link>
          </li>
          <li onClick={onClose}>
            <button
              className={styles.add__btn}
              onClick={() => navigate('/vote/notification')}
            >
              طلبات الأنضمام للترشيح
              <Badge badgeContent={dataNotVote?.data?.length} color="primary" anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
                <IoNotifications />
              </Badge>
            </button>
          </li>
          <li onClick={onClose}>
            <button
              onClick={() => {
                Cookies.remove("token");
                Cookies.remove("user");
                navigate("/login");
              }}
            >
              {t("logout")}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MainSidebar;
