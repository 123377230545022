import React, { useEffect, useState } from 'react'
import { useApi } from '../../../hooks/useApi'
import { PageHeader } from '../../../layout';
import styles from './.module.scss';
import NotificationBox from './_components/NotificationBox';
import dayjs from "dayjs";
import 'dayjs/locale/ar'; // Import Arabic locale
import Skeleton from 'react-loading-skeleton';
import { Box, Button } from '@mui/material';


const VoteNotification = () => {

    const [status, setStatus] = useState({})

    const { onRequest: onRequestNotVote, data: dataNotVote, loading: loadingNotVote } = useApi("/api/vote_notification", "get");

    const { loading, onRequest } = useApi(`/api/voteUserAction`, "post");


    useEffect(() => {
        onRequestNotVote()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmit = async (newStatus) => {
        const res = await onRequest({
            notification_id: newStatus?.notification_id,
            vote_id: newStatus?.vote_id,
            status: newStatus?.status
        });
        if (res?.success) {
            // Handle success (e.g., show a message, update UI, etc.)
            onRequestNotVote()
        } else {
            // Handle error
            console.error("Failed to submit status");
        }
    }
    return (
        <> <PageHeader title={'طلبات الترشيح '} />
            <div className={`${styles.page} container`}>
                <div className={styles.list}>
                    {loadingNotVote ? (
                        Array(8).fill("").map((_, i) => (
                            <Skeleton width="100%" height="114px" key={i} />
                        ))
                    ) :
                        dataNotVote?.data?.map((notification) => (
                            <NotificationBox
                                type={'diwan'}
                                key={notification?.id}
                                title={notification?.title}
                                content={notification?.content}
                                date={dayjs(notification?.created_at)
                                    .locale("ar")
                                    .format("DD  MMMM  YYYY")}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                                    <Button
                                    disabled={loading}
                                        onClick={() => {
                                            const newStatus = {
                                                notification_id: notification?.notification_id,
                                                vote_id: +notification?.vote_id,
                                                status: 'accept'
                                            };
                                            setStatus(newStatus);
                                            onSubmit(newStatus);
                                        }}
                                        style={{
                                            background: "#06a741",
                                            color: "#FFF",
                                        }}
                                    >
                                        {"قبول"}
                                    </Button>
                                    <Button
                                    disabled={loading}
                                        onClick={() => {
                                            const newStatus = {
                                                notification_id: notification?.notification_id,
                                                vote_id: +notification?.vote_id,
                                                status: 'reject'
                                            };
                                            setStatus(newStatus);
                                            onSubmit(newStatus);
                                        }}
                                        style={{ background: "#f00000", color: "#fff" }}
                                    >
                                        رفض
                                    </Button>
                                </Box>
                            </NotificationBox>
                        ))}
                </div>
            </div>
        </>
    )
}

export default VoteNotification