import React, { useContext } from "react";
import styles from "./.module.scss";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../../../context/ModalContext";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { DeleteModal } from "../../../../components";
import CategoryForm from "../CategoryForm";
import Cookies from "js-cookie";

const CategoryBox = ({ vote, onGetCategories }) => {
  const { idModal, setIdModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("vote");
  return (
    <>
      <div
        className={styles.box}
        style={{
          backgroundColor: vote?.color,
        }}
      >
        <button
          onClick={() =>
            navigate(`${vote?.id}`, {
              state: { data: vote?.name },
            })
          }
          className={styles.link}
        ></button>
        {vote?.name}
        {permission &&
          <div className={styles.category__btns}>
            <button
              style={{ color: vote?.color || "#000" }}
              className={styles.edit}
              onClick={() => setIdModal(`edit-${vote?.id}-category`)}
            >
              <MdEdit />
            </button>
            <button
              style={{ color: vote?.color || "#000" }}
              className={styles.delete}
              onClick={() => setIdModal(`delete-category-${vote?.id}`)}
            >
              <MdDeleteForever />
            </button>
          </div>
        }
      </div>
      {idModal === `edit-${vote?.id}-category` && (
        <CategoryForm
          categoryId={vote?.id}
          onGetList={onGetCategories}
          defaultData={vote}
        />
      )}
      {idModal === `delete-category-${vote?.id}` && (
        <DeleteModal
          body={{
            category_id: vote?.id,
          }}
          id={vote?.id}
          endpoint="deleteVoteCategory"
          title="هل أنت متأكد أنك تريد حذف هذه الفئة"
          getList={onGetCategories}
        />
      )}
    </>
  );
};

export default CategoryBox;
