import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import VoteForm from '../VoteForm';
import { PageHeader } from '../../../layout';
import { useApi } from '../../../hooks/useApi';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import uploadFile from '../../../utils/uploadImages';

const AddNewVote = () => {

  const { t } = useTranslation();
  const { slug } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  // ADD SCHEMA
  // Add the custom validation for start_date and end_date
  const schema = yup.object({
    title: yup.string().required(t("errors.required")),
    start_date: yup
      .string()
      .required(t("errors.required"))
      .test(
        "is-before-end-date",
        t("errors.start_date_must_be_before_end_date"), // Custom error message
        function (value) {
          const { end_date } = this.parent; // Get the end_date value
          return !end_date || Date.parse(value) <= Date.parse(end_date); // Ensure start_date is before or equal to end_date
        }
      ),
    end_date: yup
      .string()
      .required(t("errors.required"))
      .test(
        "is-after-start-date",
        t("errors.end_date_must_be_after_start_date"), // Custom error message
        function (value) {
          const { start_date } = this.parent; // Get the start_date value
          return !start_date || Date.parse(value) >= Date.parse(start_date); // Ensure end_date is after or equal to start_date
        }
      ),
    finish_with: yup.string().required(t("errors.required")),
    win_count: yup.string().required(t("errors.required")),
    vote_user_count: yup.string().required(t("errors.required")),
    vote_total_user_count: yup.string().required(t("errors.required")),
    view_vote_to: yup.string().required(t("errors.required")),
    min_voter_age: yup.string(),
    view_result_to: yup.string().required(t("errors.required")),
    images: yup.array().min(1, t("errors.at_least_one_image")).required(t("errors.image_required")),
  });



  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  // send diwaniya base-info
  const { onRequest: AddVote } = useApi("/api/addVote", "post");



  const onSubmit = async e => {

    setSubmitting(true);

    // Base-info
    const body = {
      title: e.title,
      start_date: e.start_date,
      end_date: e.end_date,
      finish_with: e.finish_with,
      win_count: e.win_count,
      vote_user_count: e.vote_user_count,
      vote_total_user_count: e.vote_total_user_count,
      view_vote_to: e.view_vote_to,
      min_voter_age: e.min_voter_age || '',
      view_result_to: e.view_result_to,
      category_id: slug,
      
    };

    try {
     const res = await AddVote(body, "IGNOREMESSAGEEVER");
      if (res?.success) {
        await uploadFile({
          images: e?.images,
          category_type: "votes",
          category_id: res?.data?.id,
        });
        toast.success("تمت العملية بنجاح");
        navigate(`/votes/categories`);
        setSubmitting(false);
      }

    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
    setSubmitting(false);
  }

  return (
    <>
      <PageHeader title={'اضافة تصويت جديد'} />
      <div className="container">
        <VoteForm
          onSubmit={handleSubmit((e) => onSubmit(e))}
          control={control}
          register={register}
          formData={watch()}
          errors={errors}
          submitting={submitting}
        />
      </div>
    </>
  );
}

export default AddNewVote