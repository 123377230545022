import React, { useEffect, useState, useCallback } from "react";
import styles from './.module.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../layout';
import { IoMdAdd } from "react-icons/io";
import DiwaniyaBox from './_components/DiwaniyaBox';
import { useApi } from "../../../hooks/useApi";
import Skeleton from "react-loading-skeleton";
import { EmptyList, Error } from "../../../components";
import { FaLongArrowAltDown } from "react-icons/fa";
import Cookies from "js-cookie";

const SingleDiwaniya = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [sorting, setSorting] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [diwaniyasList, setDiwaniyasList] = useState([]);
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("diwan");

  useEffect(() => {
    !state?.data && navigate("/diwaniyas");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data]);

  // get diwaniyas
  const {
    data: diwaniyas,
    loading: diwaniyasLoading,
    onRequest: onGetDiwaniyas,
    error: diwaniyasError,
  } = useApi(
    `/api/viewDiwan?current_page=${currentPage}&per_page=15&category_id=${slug}&sort=${sorting}`,
    "get"
  );

  useEffect(() => {
    if (slug) onGetDiwaniyas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, sorting, currentPage]);

  useEffect(() => {
    if (diwaniyas?.data) {
      setDiwaniyasList((prevList) => [...prevList, ...diwaniyas.data]);
    }
  }, [diwaniyas?.data]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (diwaniyasList.length < diwaniyas?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [diwaniyasList.length, diwaniyas?.count]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handelSorting = () => {
    setCurrentPage(1); // إعادة ضبط الصفحة الحالية إلى 1 عند تغيير الفرز
    setDiwaniyasList([]); // مسح القائمة لإعادة تحميل البيانات وفق الترتيب الجديد
    setSorting((prevSorting) =>
      prevSorting === null ? "asc" : prevSorting === "asc" ? "desc" : null
    );
  };
  const handleUpdateCategories = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة الأولى
    setDiwaniyasList([]); // تفريغ القائمة الحالية
    onGetDiwaniyas(); // جلب البيانات من جديد
  };
  return (
    <>
      <PageHeader title={state?.data || "Unknown"} backHref={"/diwaniyas"} />
      <div className={`container`}>
        <div className={styles.page__header}>
          {permission && (
            <Link to={`/diwaniyas/add/${slug}`} className={styles.header__btn}>
              {t("AddNewDiwaniya")} <IoMdAdd />
            </Link>
          )}
          <button
            onClick={handelSorting}
            className={`${styles.header__btn} ${sorting === null
                ? styles.off
                : sorting === "asc"
                  ? styles.increase
                  : styles.decrease
              }`}
          >
            <FaLongArrowAltDown />
            {t("sortingByName")}
          </button>
        </div>
        {diwaniyasError ? (
          <Error msg={diwaniyasError?.message} />
        ) : diwaniyasLoading && currentPage === 1 ? ( // عرض الـ Skeletons في حالة التحميل للصفحة الأولى فقط.
          <div className={styles.list}>
            {Array(5)
              ?.fill("")
              ?.map((_, i) => (
                <Skeleton
                  key={i}
                  width="100%"
                  height="213px"
                  borderRadius="4px"
                />
              ))}
          </div>
        ) : !diwaniyasList.length ? (
          <EmptyList text="لا يوجد أي ديوانيات في الوقت الراهن، الآن يمكنك إضافة الديوانيات" />
        ) : (
          <div className={styles.list}>
            {diwaniyasList.map((diwaniya) => (
              <DiwaniyaBox
                key={diwaniya?.id}
                diwaniya={diwaniya}
                onGetList={handleUpdateCategories}
                permission={permission}
                cancelled={diwaniya?.exist === "0" ? true : false}
              />
            ))}
          </div>
        )}
        {diwaniyasLoading && currentPage > 1 && (
          <div className={styles.skeletonWrapper}>
            <Skeleton
              width="100%"
              height="213px"
              borderRadius="4px"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default SingleDiwaniya;
