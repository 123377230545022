import React, { useContext } from "react";
import styles from "./.module.scss";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "../../../../context/ModalContext";
import { MdDeleteForever, MdEdit } from "react-icons/md";
import { DeleteModal } from "../../../../components";
import CategoryForm from "../CategoryForm";
import Cookies from "js-cookie";

const CategoryBox = ({ activate, onGetCategories }) => {
  const { idModal, setIdModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("activity"); // activate
  return (
    <>
      <div
        className={styles.box}
        style={{
          backgroundColor: activate?.color,
        }}
      >
        <button
          onClick={() =>
            navigate(`${activate?.id}`, {
              state: { data: activate?.name },
            })
          }
          className={styles.link}
        ></button>
        {activate?.name}
        {permission &&
          <div className={styles.category__btns}>
            <button
              style={{ color: activate?.color || "#000" }}
              className={styles.edit}
              onClick={() => setIdModal(`edit-${activate?.id}-category`)}
            >
              <MdEdit />
            </button>
            <button
              style={{ color: activate?.color || "#000" }}
              className={styles.delete}
              onClick={() => setIdModal(`delete-category-${activate?.id}`)}
            >
              <MdDeleteForever />
            </button>
          </div>
        }
      </div>
      {idModal === `edit-${activate?.id}-category` && (
        <CategoryForm
          categoryId={activate?.id}
          onGetList={onGetCategories}
          defaultData={activate}
        />
      )}
      {idModal === `delete-category-${activate?.id}` && (
        <DeleteModal
          body={{
            category_id: activate?.id,
          }}
          id={activate?.id}
          endpoint="deleteActivityCategory"
          title="هل أنت متأكد أنك تريد حذف هذه الفئة"
          getList={onGetCategories}
        />
      )}
    </>
  );
};

export default CategoryBox;
