import { Box, Button, Typography, LinearProgress, Modal, CardMedia } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../hooks/useApi';
import { toast } from 'react-toastify';
import DefaultCover from "../../../assets/DefaultCover.png";

const VotesResults = ({ permission, slug, styles, state }) => {

    const { data: results, onRequest: onGetResults } = useApi(`/api/viewVoteResult?vote_id=${slug}`, "get");
    // All Votes Numper
    const [totalVotes, setTotalVotes] = useState(0);
    // state open model
    const [open, setOpen] = useState(false);
    // selected user show in model
    const [selectedVoters, setSelectedVoters] = useState([]);
    // calculate total votes
    useEffect(() => {
        if (results?.data) {
            const votes = results.data.reduce((acc, curr) => acc + curr.usersThatVoted.length, 0);
            setTotalVotes(votes);
        }
    }, [results]);

    useEffect(() => {
        onGetResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // fun open model 
    const handleOpen = (users) => {
        if (state.data.view_result_to === "1" || (state.data.view_result_to === "2" && permission)) {
            setSelectedVoters(users);
            setOpen(true);
        } else {
            toast.error('لا تملك الأذن لمشاهدة التصويتات'); 
        }
    };

    const handleClose = () => setOpen(false);

    return (
        <div className="container">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography sx={{ p: 2, fontWeight: 'bold' }}
                    variant='h6' className={styles.title}>
                    {`التصويت ل${state?.data?.title}`}
                </Typography>
                {/* <Button
                    sx={{
                        zIndex: 99,
                        backgroundColor: '#DBDBDB',
                        color: '#7B7B7B',
                        ':hover': { backgroundColor: '#DBDBDB', color: '#7B7B7B' },
                        width: 'fit-content', px: 2
                    }}>
                    25 نوفمبر 2022
                </Button> */}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                <Typography sx={{ fontWeight: 'bold' }}
                    variant='h6' className={styles.title}>
                    نتائج التصويت
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 'bold', color: '#AEAEAE' }} className={styles.title}>
                        اجمالي عدد المصوتين
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', color: '#AEAEAE' }} className={styles.title}>
                        {totalVotes}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ mt: 4 }}>
                {results?.data?.map((result, index) => {
                    const votesCount = result.usersThatVoted.length;
                    const percentage = totalVotes > 0 ? (votesCount / totalVotes) * 100 : 0;

                    return (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
                                {result.name}
                            </Typography>
                            <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={percentage}
                                    sx={{
                                        height: 30,
                                        width: '100%',
                                        borderRadius: 2,
                                        backgroundColor: '#CCCCCC',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#26C0FF',
                                        },
                                    }}
                                />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        position: 'absolute',
                                        left: `${percentage === 0 ? 2 : (percentage - '4')}%`,
                                        transform: 'translateX(-50%)',
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}>
                                    {percentage === 0 ? '0%' : Math.round(percentage) + '%'}
                                </Typography>
                            </Box>
                            {(state.data.view_result_to === "1" || (state.data.view_result_to === "2" && permission) )&&<Button
                                onClick={() => handleOpen(result?.usersThatVoted)}
                                disabled={votesCount === 0}
                                sx={{ mt: 1 }}>
                                عرض المصوتين
                            </Button>}
                        </Box>
                    );
                })}
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="voters-modal-title"
                aria-describedby="voters-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                        borderRadius: '8px'
                    }}>
                    <Typography id="voters-modal-title" variant="h6" component="h2">
                        قائمة المصوتين
                    </Typography>
                    <Box id="voters-modal-description" sx={{ mt: 2 }}>
                        {selectedVoters?.length > 0 ? (
                            selectedVoters?.map((user, index) => (
                                <Box sx={{ borderRadius: '6px', backgroundColor: '#F8F8F8', mb: 2 }} key={user?.id} className={styles.box}>
                                    <div className={styles.image__box}>
                                        <CardMedia
                                            component={'img'}
                                            src={user?.user?.profile_picture || DefaultCover}
                                            alt={"user_image"}
                                            className={styles.img}
                                            sx={{ borderRadius: '50%', objectFit: 'cover', width: 75, height: 75, m: '8px auto' }}
                                        />
                                    </div>
                                    <Box>
                                        <h4 className={styles.title}>
                                            {user?.user?.first_name}
                                        </h4>
                                        <Typography sx={{ px: 2 }}>
                                            {user?.user?.second_name}
                                        </Typography>
                                        <Typography sx={{ px: 2 }}>
                                            {user?.user?.date_of_birth}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography>لا يوجد مصوتين لعرضهم.</Typography>
                        )}
                    </Box>
                    <Button onClick={handleClose} sx={{ mt: 2 }}>
                        إغلاق
                    </Button>
                </Box>
            </Modal>

        </div>
    )
}

export default VotesResults;
