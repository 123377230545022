import FamilyTree from "../../../assets/family-tree@2x.png";
import  Seats from '../../../assets/Seats@2x.png';
import events from '../../../assets/events@2x.png';
import congrats from "../../../assets/congrats@2x.png";
import news from "../../../assets/news@2x.png";
import products from "../../../assets/products@2x.png";
import activities from "../../../assets/activities@2x.png";
import voting from "../../../assets/voting@2x.png";
import contact from "../../../assets/contact@2x.png";
import money from "../../../assets/money@2x.png";


export const images = [
  FamilyTree,
  Seats,
  events,
  congrats,
  news,
  products,
  activities,
  voting,
  contact,
  money,
];