import React, { useContext } from 'react';
import styles from './.module.scss';
import DefaultCover from "../../../../../assets/DefaultCover.png";
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from '../../../../../components';
import { ModalContext } from '../../../../../context/ModalContext';
import { DeleteIcon, EditIcon } from '../../../../../icons';

const VoteBox = ({ vote, onGetList, permission,  }) => {

  const { idModal, setIdModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <>
      <div type="button" className={styles.box}>
        <div
          className={styles.image__box}
          onClick={(e) => {
            navigate(`/vote/${vote?.id}`, { state: { data: vote } });
          }}
        >
          <img
            src={vote?.image || DefaultCover}
            alt={"vote_image"}
            className={styles.img}
          />
        </div>
        <h4
          className={styles.title}
          onClick={(e) => {
            navigate(`/vote/${vote?.id}`, { state: { data: vote } });
          }}
        >
          {vote?.title}
        </h4>
        {permission &&
          <>
            <div className={styles.btns}>
              <button
                style={{ display: "flex" }}
                onClick={() => {
                  navigate(`/votes/edit/${vote?.id}`, { state: { data: vote } });
                }}
              >
                <EditIcon />
              </button>
              <button
                type="button"
                className={styles.delete__btn}
                onClick={() => setIdModal(`delete-news-${vote?.id}`)}
              >
                <DeleteIcon />
              </button>
            </div>
          </>}
      </div>
      {idModal === `delete-news-${vote?.id}` && (
        <DeleteModal
          body={{
            vote_id: vote?.id,
          }}
          endpoint="deleteVote"
          title="هل أنت متأكد أنك تريد حذف هذا التصويت"
          getList={onGetList}
        />
      )}
    </>
  );
};

export default VoteBox