import React, { useEffect } from "react";
import { useApi } from "../../hooks/useApi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "../../layout";
import { EmptyList, Error } from "../../components";
import styles from "./.module.scss";
import Skeleton from "react-loading-skeleton";
import FileBox from "./_components/FileBox";
import { IoMdAdd } from "react-icons/io";

const Archives = () => {
  const { slug } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();



  useEffect(() => {
    !state?.data && navigate("/archives");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data]);

  // get archives
  const {
    data: archives,
    loading: archivesLoading,
    onRequest: onGetArchives,
    error: archivesError,
  } = useApi(
    `/api/viewArchive?current_page=1&per_page=10000&category_id=${slug}`,
    "get"
  );
  console.log("🚀 ~ Archives ~ archives:", archives)

  useEffect(() => {
    if (slug) onGetArchives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);


  return (
    <div className={`container`}>
      <PageHeader title={state?.data || "Unknown"} backHref={"/archives"} />
      
        <Link to={`/archives/${slug}/add`} className={styles.header__btn}>
          {"أضافة عنصر جديد"} <IoMdAdd />
        </Link>
      {archivesError ? (
        <Error msg={archivesError?.message} />
      ) : archivesLoading ? (
        <div className={styles.list}>
          {Array(5)
            ?.fill("")
            ?.map((_, i) => (
              <Skeleton
                key={i}
                width="100%"
                height="213px"
                borderRadius="4px"
              />
            ))}
        </div>
      ) : !archives?.data?.length ? (
        <EmptyList text="لا يوجد أي عناصر في الوقت الراهن، الآن يمكنك إضافة عنصر جديد" />
      ) : (
        <div className={styles.list}>
          {archives?.data?.map((archive) => (
            <FileBox
              key={archive?.id}
              id={archive.id}
              description={archive?.description}
              type={archive?.type}
              url={archive?.url}
              created_at={archive?.created_at}
              userImage={archive?.user?.profile_picture}
              userName={
                archive?.user?.name ||
                `${archive?.user?.first_name}`
              }
              file={archive?.file}
              onGetList={onGetArchives}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Archives;
