import React, { useState } from "react";
import { DatePicker, ErrorMessage, MainButton, MainGoogleMap, MainInput, UploadImages } from "../../../components";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import { Controller } from "react-hook-form";
import { Button } from "@mui/material";

const ActivitiesForm = ({ onSubmit, control, register, formData, errors, edit, submitting, }) => {
  const { t } = useTranslation();
  const [openMap, setOpenMap] = useState(false);

  return (
    <form onSubmit={onSubmit} className={styles.form__content}>

      {/* Name */}
      <MainInput
        register={register}
        placeholder={t("اسم النشاط")}
        label="اسم النشاط"
        type="text"
        name="title"
        value={formData?.name}
        error={errors?.name?.message}
        required
      />
      {/* start Date */}
      <DatePicker
        control={control}
        name="start_date"
        error={errors?.start_date?.message}
        date={formData?.start_date}
        defaultDate
        type='بداية النشاظ'
      />
      {/* end_date */}
      <DatePicker
        control={control}
        name="end_date"
        error={errors?.end_date?.message}
        date={formData?.end_date}
        defaultDate
        type='نهاية النشاط'
      />
      {/* "العدد المسموح */}
      <MainInput
        register={register}
        placeholder={t("العدد المسموح")}
        label="العدد المسموح"
        type="text"
        name="allowed_count"
        value={formData?.name}
        error={errors?.name?.message}
        required
      />
      {/* الحضور */}
      <MainInput
        register={register}
        placeholder={t("الحضور")}
        label="الحضور"
        type="text"
        name="presence_type"
        value={formData?.name}
        error={errors?.name?.message}
        required
      />

      {/* Google Map */}
      <Button
        className={`${styles.map__button} ${formData?.location?.lat ? styles.active : ""
          }`}
        sx={{ width: 'fit-content', backgroundColor: '#26C0FF', color: '#FFF', ':hover': { backgroundColor: '#26C0FF', color: '#FFF' }, px:2,}}
        type="button"
        onClick={() => setOpenMap(true)}
      >
        {formData?.location?.lat
          ? `lat: ${formData?.location?.lat} | lng: ${formData?.location?.lng}`
          : "حدد   موقع  النشاط   (لوكيشن) *"}

        {errors?.location?.lat?.message && (
          <ErrorMessage msg={errors?.location?.lat?.message} />
        )}
      </Button>
      {openMap && (
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="location"
          render={({ field: { onChange, onBlur } }) => (
            <MainGoogleMap
              onChange={(e) => onChange(e)}
              onCloseMap={() => {
                onBlur();
                setOpenMap(false);
              }}
              onBlur={onBlur}
              activeLocation={formData?.location}
            />
          )}
        />
      )}
      {/* Diwaniya image */}
      <UploadImages
        images={formData?.images || []}
        error={errors?.images?.message}
        control={control}
        name="images"
      />

      <MainButton loading={submitting} disabled={submitting} type="submit">
        {t(edit ? "edit" : "add")}
      </MainButton>
    </form>
  );
};

export default ActivitiesForm;
