import React from "react";
import styles from "./.module.scss";
import { Link } from "react-router-dom";

const MainBox = ({ title, image, href, setShow }) => {
  const disableTree = title === 'شجرة العائلة الكريمة';
  return (
    <span onClick={() => disableTree && setShow(true)} >
      <Link to={href} className={styles.box}>
        <img src={image} alt="section__image" className={styles.image} />
        <h5 className={styles.title}>{title}</h5>
      </Link>
    </span>
  );
};

export default MainBox;
