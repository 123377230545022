import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PageHeader } from '../../layout';
import { useTranslation } from 'react-i18next';
import { EmptyList, Error, MainSlider } from '../../components';
import styles from './.module.scss';
import { useApi } from '../../hooks/useApi';
import Skeleton from 'react-loading-skeleton';
import { IoMdAdd } from 'react-icons/io';
import { ModalContext } from '../../context/ModalContext';
import CategoryForm from './CategoryForm';
import CategoryBox from './CategoryBox';
import Cookies from 'js-cookie';

const Diwaniyas = () => {
  const { t } = useTranslation();
  const { setIdModal, idModal } = useContext(ModalContext);
    const { userPermission } = JSON.parse(Cookies.get("user"));
    const permission = userPermission.includes("diwan");
  const [currentPage, setCurrentPage] = useState(1);
  const [diwaniyasCatList, setDiwaniyasCatList] = useState([]);

  // get diwaniya slider:=
  const { data: slider, loading: sliderLoading, onRequest: onGetSlider, error: SliderError } = useApi("/api/viewDiwanSlider", "get");

  // get diwaniyas categories
  const {
    data: categories,
    loading: categoriesLoading,
    onRequest: onGetCategories,
    error: categoryError
  } = useApi(`/api/viewDiwanCategory?current_page=${currentPage}&per_page=15`, "get");

  useEffect(() => {
    onGetSlider();
    onGetCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (categories?.data) {
      setDiwaniyasCatList((prevList) => [...prevList, ...categories.data]);
    }
  }, [categories?.data]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (diwaniyasCatList.length < categories?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [diwaniyasCatList.length, categories?.count]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleUpdateCategories = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة الأولى
    setDiwaniyasCatList([]); // تفريغ القائمة الحالية
    onGetCategories(); // جلب البيانات من جديد
  };
  return (
    <>
      <PageHeader title={t("Diwaniyas")} backHref="/" />
      <div className={`${styles.page} container`}>
        {SliderError ? (
          <Error msg={SliderError?.message} />
        ) : (
          <MainSlider
            loading={sliderLoading}
            images={slider?.data?.filter((item) => {
              if (item?.image) {
                return {
                  image: item?.image,
                  ...item,
                };
              }
            })}
            type="diwaniyas"
          />
        )}
        {permission && (
          <button
            className={styles.add__btn}
            onClick={() => setIdModal("add-new-category")}
          >
            إضافة فئة جديدة <IoMdAdd />
          </button>
        )}
        <div className={styles.list}>
          {categoriesLoading ? (
            Array(4)
              .fill("")
              .map((_, i) => (
                <Skeleton
                  width="100%"
                  height="87px"
                  borderRadius="8px"
                  key={i}
                />
              ))
          ) : categoryError ? (
            <Error msg={categoryError?.message} />
          ) : categories?.data?.length ? (
                diwaniyasCatList?.map((diwaniya, i) => (
              <CategoryBox
                key={i}
                diwaniya={diwaniya}
                onGetCategories={handleUpdateCategories}
              />
            ))
          ) : (
            <EmptyList text="لا توجد فئات للديوانيات الآن، يمكنك إضافة فئة جديدة" />
          )}
        </div>
      </div>
      {idModal === "add-new-category" && (
        <CategoryForm onGetList={onGetCategories} />
      )}
    </>
  );
}

export default Diwaniyas