import React, { useEffect } from 'react'
import { PageHeader } from '../../../layout'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import styles from './.module.scss';
import AddUsersToVote from './AddUsersToVote';
import ActionUsersToVote from './ActionUsersToVote';
import VotesResults from './VotesResults';

const SingleVote = () => {

    const { state } = useLocation();
    
    const currentDate = new Date();

    // Parse the start date from the state (assuming it's in the format 'YYYY-MM-DD')
    const startDate = new Date(state?.data?.start_date);

    // Check if the current date is smaller than the start date
    const isBeforeStartDate = currentDate < startDate;
    const { slug } = useParams();
    const navigate = useNavigate();
    const { userPermission } = JSON.parse(Cookies.get("user"));
    const permission = userPermission.includes("vote");

    useEffect(() => {
        !state?.data && navigate("/votes/categories");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.data]);

    return (
        <>
            <PageHeader title={state?.data?.title} backHref={"/votes/categories"} />
            {state?.data?.all_voters_take_action === '0' && permission &&
                <AddUsersToVote permission={permission} state={state} slug={slug} styles={styles} />}
            {state?.data?.all_voters_take_action === '1' && state?.data?.completed === '0' &&
                <ActionUsersToVote permission={permission} state={state} slug={slug} styles={styles} isBeforeStartDate={isBeforeStartDate} />}
            {state?.data?.completed === '1' &&
                <VotesResults permission={permission} state={state} slug={slug} styles={styles} />}
        </>
    )
}

export default SingleVote