import React, { useEffect, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { Button, CardMedia, Checkbox, TextField, Typography } from '@mui/material';
import { useApi } from '../../../hooks/useApi';
import DefaultCover from "../../../assets/DefaultCover.png";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddUsersToVote = ({ permission, slug, styles, state }) => {

  const navigate = useNavigate();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [descriptions, setDescriptions] = useState({});
  const { loading, onRequest: addUser } = useApi(`/api/voteAdminRequest`, "post");
  const { data: users, onRequest: onGetUsers } = useApi(`/api/getAllVotedForUsers`, "get");

  console.log("🚀 ~ AddUsersToVote ~ users:", users)
  const isDisableClick = selectedUsers?.length < state?.data?.vote_total_user_count;
  const isVoterAction = state?.data?.all_voters_take_action === "1";
  const vote_admin_request = state?.data?.vote_admin_request
  const isShowChick = (isDisableClick && !isVoterAction) && !vote_admin_request;

  useEffect(() => {
    onGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const handleCheckboxChange = (userId) => {
    if (descriptions[userId]?.trim()) {
      setSelectedUsers((prevSelected) =>
        prevSelected.includes(userId)
          ? prevSelected.filter((id) => id !== userId)
          : [...prevSelected, userId]
      );
    } else {
      toast.error('يرجى ملء تفاصيل المرشح قبل التحقق من الاختيار');
    }
  };

  const handleDescriptionChange = (userId, value) => {
    setDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [userId]: value,
    }));
  };

  const handleImageClick = (userId) => {
    handleCheckboxChange(userId);
    
  };
  const handleSubmit = async () => {
    const incompleteDescriptions = selectedUsers.some((userId) => !descriptions[userId]?.trim());

    if (incompleteDescriptions) {
      toast.error('يرجى ملء تفاصيل لكل مرشح قبل الإرسال');
      return;
    }
    

    const voteAdminRequest = selectedUsers.map((userId) => ({
      to_user: userId,
      description: descriptions[userId] || '',
    }));

    const requestData = {
      vote_id: slug, // assuming slug is the vote_id
      voteAdminRequest,
    };

    try {
      const res = selectedUsers.length > 0 ? await addUser(requestData) : toast.error('يرجى اختيار مرشح');
      if (res?.success) {
        // Handle success (e.g., navigate, show a success message, clear the form, etc.)
        setSelectedUsers([]);
        setDescriptions({});
        navigate(`/votes/categories/${state?.data?.category_id}`); // Replace with the correct route
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="container">
      <div className={styles.page__header}>
        {
          vote_admin_request ? <Typography sx={{ p: 2, fontWeight:'500' }} variant='h6' className={styles.title}>
            تم أختيار المرشحين مسبقا</Typography> :
        <Typography variant='h6' sx={{p:2, fontWeight:'500'}} className={styles.title}>
          {!isDisableClick ? 'تم الوصول للحد الأقصى للمرشحين أرسل الآن' : 'أختر المرشحين الآن'}</Typography>
        }
        {permission && !isDisableClick && (
          <Button
            disabled={loading || isVoterAction}
            sx={{
              position: 'fixed',
              left: '50%',
              bottom: '5%',
              transform: 'translateX(-50%)',
              zIndex: 99,
              backgroundColor: '#26C0FF',
              color: '#fff',
              ':hover': { backgroundColor: '#26C0FF', color: '#fff' },
            }}
            className={styles.header__btn}
            onClick={handleSubmit}
          >
            ارسال دعوات لدخول التصويت <IoMdAdd />
          </Button>
        )}
      </div>
      <div className={styles.list}>
        {users?.data?.map((user) => (
          <div key={user?.id} className={styles.box}>
            <div onClick={() => isShowChick && handleImageClick(user?.id)} className={styles.image__box}>
              <CardMedia
                component={'img'}
                src={user?.profile_picture || DefaultCover}
                alt={"user_image"}
                className={styles.img}
                sx={{ borderRadius: '50%', objectFit: 'cover', width: 75, height: 75, m: '8px auto' }}
              />
            </div>
            <h4 className={styles.title}>
              {user?.name?.slice(0, 15)}
            </h4>
            {permission && isShowChick && (
              <>
                <Checkbox
                  checked={selectedUsers.includes(user?.id)}
                  onChange={() => handleCheckboxChange(user?.id)}
                  color="primary"
                  className={styles.btns}
                />
                <TextField
                  label="تفاصيل"
                  value={descriptions[user?.id] || ''}
                  onChange={(e) => handleDescriptionChange(user?.id, e.target.value)}
                  multiline
                  rows={2}
                  fullWidth
                  variant="outlined"
                  className={styles.descriptionInput}
                />
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddUsersToVote;
