import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './styles/global.scss';
import "react-toastify/dist/ReactToastify.css";
import './i18n';
import { ModalProvider } from './context/ModalContext';
import { Notification } from './components';

// تسجيل الـ Service Worker
if ('serviceWorker' in navigator) {
  console.log("🚀 ~ navigator:", navigator)
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}
if (navigator.setAppBadge) {
  console.log("The App Badging API is supported!");
}
navigator.setAppBadge(42); 
navigator.clearAppBadge();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ModalProvider>
      <App />
      <Notification />
    </ModalProvider>
    <ToastContainer style={{ zIndex: 100000000 }} />
  </BrowserRouter>
);
