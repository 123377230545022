import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PageHeader } from "../../../layout";
// import CongratulationsForm from "../CongratulationsForm";
import ActivitiesForm from "../ActivitiesForm";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import uploadFile from "../../../utils/uploadImages";
import { useApi } from "../../../hooks/useApi";

const AddActivities = () => {
  const { t } = useTranslation();

  const navigate = useNavigate()

  const { slug } = useParams();
  console.log("🚀 ~ AddActivities ~ slug:", slug)
  const [submitting, setSubmitting] = useState(false);
  // ADD SCHEMA
  const schema = yup.object({
    title: yup.string("").required(t("errors.required")),
    start_date: yup
      .string()
      .required(t("errors.required"))
      .test(
        "is-before-end-date",
        t("errors.start_date_must_be_before_end_date"), // Custom error message
        function (value) {
          const { end_date } = this.parent; // Get the end_date value
          return !end_date || Date.parse(value) <= Date.parse(end_date); // Ensure start_date is before or equal to end_date
        }
      ),
    end_date: yup
      .string()
      .required(t("errors.required"))
      .test(
        "is-after-start-date",
        t("errors.end_date_must_be_after_start_date"), // Custom error message
        function (value) {
          const { start_date } = this.parent; // Get the start_date value
          return !start_date || Date.parse(value) >= Date.parse(start_date); // Ensure end_date is after or equal to start_date
        }
      ),
    allowed_count: yup.string("").required(t("errors.required")),
    presence_type: yup.string("").required(t("errors.required")),
    location: yup
      .object({
        lat: yup.string().required(t("errors.required")),
        lng: yup.string().required(t("errors.required")),
      }),
    images: yup.array().min(1, "at least 1 item").required("image is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { onRequest: onSendBaseInfo } = useApi("/api/addActivity", "post");

  const onSubmit = async (e) => {
    console.log("🚀 ~ onSubmit ~ e:", e)
    setSubmitting(true);

    // Base-info
    const body = {
      title: e?.title,
      start_date: e?.start_date,
      end_date: e?.end_date,
      allowed_count: e?.allowed_count,
      presence_type: e?.presence_type,
      category_id: slug,
      lat: e?.location?.lat,
      long: e?.location?.lng,
    }; 
    console.log("🚀 ~ onSubmit ~ body:", body)
    try {
      const res = await onSendBaseInfo(body, "IGNOREMESSAGEEVER");
      if (res?.success) {
        await uploadFile({
          images: e?.images,
          category_type: "activity",
          category_id: res?.data?.id,
        });
        toast.success("تمت العملية بنجاح");
        navigate(`/activities/categories`);
      }
      // res?.success
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  return (
    <div className="container">
      <PageHeader title={t("AddActivities")} />
      <ActivitiesForm
        onSubmit={handleSubmit((e) => onSubmit(e))}
        control={control}
        register={register}
        formData={watch()}
        errors={errors}
        submitting={submitting}
      />
    </div>
  );
};

export default AddActivities;
