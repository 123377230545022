import styles from "./.module.scss";
import { FaTrashAlt, FaEdit, FaMinus, FaPlus } from "react-icons/fa";


export const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  onDelete,
  isDeleting,
  deleting,
  onUpdate,
  onShowDetails,
}) => {
  const isWife = nodeDatum?.type === "2" && nodeDatum?.marry_type === "1";

  // حساب عدد الأطفال في marry (في حال وجودهم)
  const totalMarryCount = nodeDatum?.marry?.length || 0;

  return (
    <g className={`${isWife ? 'wife' : 'husband'}`}>
      <foreignObject width={100} height={170} x={-50} y={-65}>
        <div
          className={`${styles.box} ${nodeDatum?.type === "2" ? styles.female : styles.male
            } ${nodeDatum?.is_deleted === "1" ? styles.deleted : ""}`}
          onClick={(e) => {
            if (e.target?.id) {
              return;
            } else {
              onShowDetails(nodeDatum);
            }
          }}
        >
          {isDeleting && deleting === nodeDatum?.id ? (
            <div className={styles.overlay}></div>
          ) : (
            ""
          )}
          <img
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              margin: "3px auto 0",
              display: "block",
            }}
            src={
              nodeDatum?.photo ||
              "https://up.yimg.com/ib/th?id=OIP.IKpJatzr_kSkN7k8VHXF6wHaGQ&pid=Api&rs=1&c=1&qlt=95&w=131&h=110"
            }
            alt=""
          />
          <div className={styles.btns}>
            <button
              className={styles.delete__btn}
              onClick={() => onDelete(nodeDatum?.id)}
              id="delete__btn"
            >
              <FaTrashAlt id="delete__btn" size={25} />
            </button>
            <button
              className={styles.edit__btn}
              onClick={() => onUpdate(nodeDatum)}
              id="delete__btn"
            >
              <FaEdit id="delete__btn" size={25} />
            </button>
          </div>
          <p className={styles.name}>{nodeDatum.name}</p>
          {nodeDatum?.nationality && (
            <p className={styles.nationality}>{nodeDatum.nationality}</p>
          )}
          {nodeDatum?.birthdate && (
            <p className={styles.birthdate}>{nodeDatum.birthdate}</p>
          )}
          {nodeDatum?.age && <p className={styles.age}>{nodeDatum.age} years</p>}
          {nodeDatum?.is_alive === "2" && <div className={styles.badge}></div>}
          {nodeDatum?.is_divorced === "1" && (
            <div className={`${styles.badge} ${styles.is_divorced}`}></div>
          )}
          {nodeDatum?.is_relict === "1" && (
            <div
              className={`${styles.badge} ${styles.is_divorced} ${styles.is_relict}`}
            ></div>
          )}
          {nodeDatum?.children?.length && nodeDatum?.type === "2" ? (
            <button
              onClick={() => toggleNode()}
              className={styles.collapsed__btn}
              id="delete__btn"
            >
              {!nodeDatum?.__rd3t?.collapsed ? (
                <FaMinus id="delete__btn" />
              ) : (
                <FaPlus id="delete__btn" />
              )}
            </button>
          ) : (
            ""
          )}
        </div>
      </foreignObject>

      {nodeDatum?.marry?.map((marryNode, index) => (
        <foreignObject
          key={marryNode.id}
          width={100}
          height={170}
          x={-(index + 1) * 110 - (totalMarryCount * 55)}
          y={-65}
        >
          <div
            className={`${styles.box} ${marryNode?.type === "2" ? styles.female : styles.male
              } ${marryNode?.is_deleted === "1" ? styles.deleted : ""}`}
            onClick={(e) => {
              if (e.target?.id) {
                return;
              } else {
                onShowDetails(marryNode);
              }
            }}
          >
            {isDeleting && deleting === marryNode?.id ? (
              <div className={styles.overlay}></div>
            ) : (
              ""
            )}
            <img
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                margin: "3px auto 0",
                display: "block",
              }}
              src={
                marryNode?.photo ||
                "https://up.yimg.com/ib/th?id=OIP.IKpJatzr_kSkN7k8VHXF6wHaGQ&pid=Api&rs=1&c=1&qlt=95&w=131&h=110"
              }
              alt=""
            />
            <div className={styles.btns}>
              <button
                className={styles.delete__btn}
                onClick={() => onDelete(marryNode?.id)}
                id="delete__btn"
              >
                <FaTrashAlt id="delete__btn" size={25} />
              </button>
              <button
                className={styles.edit__btn}
                onClick={() => onUpdate(marryNode)}
                id="delete__btn"
              >
                <FaEdit id="delete__btn" size={25} />
              </button>
            </div>
            <p className={styles.name}>{marryNode.name}</p>
            {marryNode?.nationality && (
              <p className={styles.nationality}>{marryNode.nationality}</p>
            )}
            {marryNode?.birthdate && (
              <p className={styles.birthdate}>{marryNode.birthdate}</p>
            )}
            {marryNode?.age && <p className={styles.age}>{marryNode.age} years</p>}
            {marryNode?.is_alive === "2" && <div className={styles.badge}></div>}
            {marryNode?.is_divorced === "1" && (
              <div className={`${styles.badge} ${styles.is_divorced}`}></div>
            )}
            {marryNode?.is_relict === "1" && (
              <div
                className={`${styles.badge} ${styles.is_divorced} ${styles.is_relict}`}
              ></div>
            )}
            {marryNode?.children?.length && marryNode?.type === "2" ? (
              <button
                onClick={() => toggleNode()}
                className={styles.collapsed__btn}
                id="delete__btn"
              >
                {!marryNode?.__rd3t?.collapsed ? (
                  <FaMinus id="delete__btn" />
                ) : (
                  <FaPlus id="delete__btn" />
                )}
              </button>
            ) : (
              ""
            )}
          </div>
        </foreignObject>
      ))}
    </g>
  );
};
