import React, { useContext, useEffect, useState } from "react";
import { PageHeader } from '../../../layout';
import styles from './.module.scss';
import { DeleteIcon } from '../../../icons';
import { DeleteModal, MainLabel, MainSlider } from '../../../components';
import { MdBookmarkAdded, MdModeEdit } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { TiLocation } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultCover from "../../../assets/DefaultCover.png";
import dayjs from "dayjs";
import 'dayjs/locale/ar'; // Import Arabic locale
import { ModalContext } from "../../../context/ModalContext";
import Cookies from "js-cookie";
import { format } from 'date-fns';
import { ar } from 'date-fns/locale';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BookModal from "../_components/BookModal";
import CancelModal from "../_components/CancelModal";

const ActivateDetails = () => {

  const [expandedId, setExpandedId] = useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedId(isExpanded ? panel : null);
  };

  const { idModal, setIdModal } = useContext(ModalContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const activity = location?.state?.data;

  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("activity");

  const totalCount = +activity?.allowed_count
  const totalLength = activity?.activityShare?.length

  const disableBook = totalLength < totalCount

  useEffect(() => {
    if (!activity) navigate(`/activities/categories/${activity?.category_id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  return (
    <>
      <div className={`container`}>
        <div className={styles.page__header}>
          <PageHeader backBtnStyle={{ right: 0 }} />
          {permission && <div className={styles.header__btns}>
            <button
              className={styles.header__btn}
              onClick={() => setIdModal(`delete-activity-${activity?.id}`)}
            >
              <DeleteIcon />
            </button>
            <button
              onClick={() => {
                navigate(`/activities/${activity?.id}/edit`, { state: { data: activity } });
              }}
              className={styles.header__btn}
            >
              <MdModeEdit />
            </button>
          </div>}
        </div>
        <MainSlider
          images={
            activity?.images?.map((item) => item?.image)?.length
              ? activity?.images?.map((item) => item?.image)
              : [DefaultCover]
          }
          height="calc(100vh - 200px)"
          breakpoints={{
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
          }}
        />
        <section className={styles.details__section}>
          <h4 className={styles.title}>{activity?.title}</h4>
          <div
            style={{ flexDirection: "column", alignItems: "start" }}
            className={styles.details__info}
          >
            <MainLabel>
              {dayjs(activity?.created_at)
                .locale("ar")
                .format("DD  MMMM  YYYY")}
            </MainLabel>
            <p>{'تفاصيل الدوري'}</p>
          </div>
          <div className={styles.details__info}>
            <MainLabel bgColor="#EBEBEB">{t("العنوان")}</MainLabel>
            <a
              target="_blank"
              className={styles.btn__blue}
              href={`http://maps.google.com/?q=${activity?.lat},${activity?.long}`}
              rel="noreferrer"
            >
              {t("شاهد الموقع على الخريطة من هنا ")}
              <TiLocation size={20} />
            </a>
            {/* <span>{activity?.lat}</span>
            <span>{activity?.long}</span> */}
          </div>
          <div className={styles.details__info}>
            <MainLabel bgColor="#EBEBEB">{t("الموعد")}</MainLabel>
            <span>
              {`خلال المدة من ${format(new Date(activity?.start_date), 'd MMMM', { locale: ar })} - ${format(new Date(activity?.end_date), 'd MMMM', { locale: ar })}`}
            </span>
          </div>
          <div className={styles.details__info}>
            <MainLabel bgColor="#EBEBEB">{t("العدد المسموح")}</MainLabel>
            <span>{activity?.allowed_count}</span>
          </div>
          <div className={styles.details__info}>
            <MainLabel bgColor="#EBEBEB">{t("الحضور")}</MainLabel>
            <span>{activity?.presence_type}</span>
          </div>
          <div className={styles.details__info}>
            <MainLabel bgColor="#EBEBEB">{t("عدد المشاركين")}</MainLabel>
            <span>{activity?.activityShare?.length || 'لا يوجد مشتركين بعد' }</span>
          </div>
          <Accordion
            defaultExpanded={1}
            expanded={expandedId}
            onChange={handleChange(1)}
            sx={{ my: '16px' }}
          >
            <AccordionSummary sx={{
              backgroundColor: '#26C0FF33',
              color: "#26C0FF33",

            }}
              expandIcon={<ExpandMoreIcon sx={{ color: "#26C0FF", }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"

            >
              <Typography color={'#000'} sx={{ fontFamily: '"Cairo", sans-serif' }} >{'أسماء المشاركين'}</Typography>
            </AccordionSummary>
            {activity?.activityShare?.map((user, i) =>
              <AccordionDetails sx={{ backgroundColor: '#F5F5F5' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                  <Typography textAlign={'right'} sx={{ fontFamily: '"Cairo", sans-serif', width: 'fit-content', px: 2, py: 1, borderRadius: '6px', background: '#EBEBEB', color: '#000' }} >
                    {i + 1}
                  </Typography>
                  <Typography textAlign={'right'} variant="body1" sx={{ fontFamily: '"Cairo", sans-serif' }} >
                    {user?.user?.first_name}
                  </Typography>
                </Box>
              </AccordionDetails>
            )}
          </Accordion>
        </section>
        <div className={styles.footer__btn} style={{ marginTop: '8px' }}>
          <a
            target="_blank"
            className={styles.btn__blue}
            href={`http://maps.google.com/?q=${activity?.lat},${activity?.long}`}
            rel="noreferrer"
          >
            {t("location")}
            <TiLocation size={20} />
          </a>
          {disableBook && activity?.bookAction === false && <button className={styles.book__btn}
            onClick={() => setIdModal(`book__activity__${activity?.id}`)}>
            حـجـز
            <MdBookmarkAdded size={20} />
          </button>}
          {activity?.bookAction === true && <button className={styles.cancel__btn}
            onClick={() => setIdModal(`cancel__activity__${activity?.id}`)}>
            الغاء الحجز
            <MdBookmarkAdded size={20} />
          </button>}
        </div>
      </div>
      {idModal === `delete-activity-${activity?.id}` && (
        <DeleteModal
          body={{
            activity_id: activity?.id,
          }}
          endpoint="deleteActivity"
          title="هل أنت متأكد أنك تريد حذف هذه النشاط"
          getList={() => navigate(`/activities/categories/${activity?.category_id}`)}
        />
      )}
      {idModal === `book__activity__${activity?.id}` && (
        <BookModal id={activity?.id} category_id={activity?.category_id} />
      )}
      {idModal === `cancel__activity__${activity?.id}` && (
        <CancelModal id={activity?.id} category_id={activity?.category_id} bookType={activity?.bookType} />
      )}
    </>
  )
}

export default ActivateDetails