import React, { useCallback, useEffect, useState } from "react";
import { PageHeader } from "../../layout";
import { useTranslation } from "react-i18next";
import { EmptyList, Error, MainSlider } from "../../components";
import styles from "./.module.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { useApi } from "../../hooks/useApi";
import Skeleton from "react-loading-skeleton";
import ActivateBox from "./_components/ActivateBox";
import Cookies from "js-cookie";

const Activities = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("activity");

    const [currentPage, setCurrentPage] = useState(1);
  const [activatesList, setActivatesList] = useState([]);

  useEffect(() => {
    !state?.data && navigate("/activities/categories");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data]);

  // get Liquidation slider:=
  const {
    data: slider,
    loading: sliderLoading,
    onRequest: onGetSlider,
    error: SliderError,
  } = useApi("/api/viewActivitySlider", "get");

  // get activates
  const {
    data: activates,
    error: activatesError,
    loading: activatesLoading,
    onRequest: onGetActivate,
  } = useApi(`/api/viewActivity?category_id=${slug}&current_page=${currentPage}&per_page=15`, "get");

  useEffect(() => {
    onGetSlider();
    onGetActivate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (activates?.data) {
      setActivatesList((prevList) => [...prevList, ...activates.data]);
    }
  }, [activates?.data]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (activatesList.length < activates?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [activatesList.length, activates?.count]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  const handleUpdateCategories = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة الأولى
    setActivatesList([]); // تفريغ القائمة الحالية
    onGetActivate(); // جلب البيانات من جديد
  };
  return (
    <div className={`${styles.page} container`}>
      <PageHeader title={t("الأنشطة والفاعليات")} />
      {SliderError ? (
        <Error msg={SliderError?.message} />
      ) : (
        <MainSlider
          loading={sliderLoading}
          images={slider?.data?.map((item) => item?.image) || []}
        />
      )}
      {permission &&
        <Link to={`/activities/add/${slug}`} className={styles.header__btn}>
          {t("إضافة نشاط")} <IoMdAdd />
        </Link>
      }
      {activatesLoading ? (
        <div className={styles.list}>
          {Array(5)
            ?.fill("")
            ?.map((_, i) => (
              <Skeleton
                key={i}
                width="100%"
                height="213px"
                borderRadius="4px"
              />
            ))}
        </div>
      ) : activatesError ? (
        <Error msg={activatesError?.message} />
      ) : activates?.data?.length ? (
        <div className={styles.list}>
          {activatesList?.map((activate) => (
            <ActivateBox
              key={activate?.id}
              activate={activate}
              onGetList={handleUpdateCategories}
              permission={permission}
            />
          ))}
        </div>
      ) : <EmptyList text="لا توجد أنشطة , يمكنك إضافة نشاط جديد الآن،" />}
    </div>
  );
};

export default Activities;
