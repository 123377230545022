import React, { useContext, useState } from 'react';
import { MainButton, Popup } from '../../../../components';
import styles from './.module.scss';
import { ModalContext } from '../../../../context/ModalContext';
import { useApi } from '../../../../hooks/useApi';
import { useNavigate } from 'react-router-dom';

const BookModal = ({ id, category_id }) => {
  const { setIdModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState('')
  // Book product:=
  const {
    loading: submitting,
    onRequest: onBookProduct,
  } = useApi("/api/bookAction", "post");

  const onSubmit = async (selectedType, selectedStatus) => {
    setStatus(selectedStatus)
    const res = await onBookProduct({
      activity_id: id,
      type: selectedType,
      status: selectedStatus,
    });
    if (res?.success) {
      setIdModal("");
      navigate(`/activities/categories/${category_id}`)
    }
  }

  return (
    <Popup>
      <p className={styles.text}>
        هل ترغب في الحضور ك
      </p>
      <div className={styles.btns}>
        <MainButton
          onClick={() => onSubmit("1", "1")}  // عند الضغط على مشارك: type = 1, status = 1
          disabled={status=== '1' && submitting}
          loading={status=== '1' && submitting}
        >
          مشارك
        </MainButton>
        <MainButton
          onClick={() => onSubmit("1", "2")}  // عند الضغط على مشاهد: type = 2, status = 2
          style={{ backgroundColor: "#e95858" }}
          disabled={status=== '2' && submitting}
          loading={status=== '2' && submitting}
        >
          مشاهد
        </MainButton>
      </div>
    </Popup>
  );
};

export default BookModal;
