import React, { useCallback, useEffect, useState } from "react";
import styles from './.module.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../layout';
import { IoMdAdd } from "react-icons/io";
import VoteBox from './_components/VoteBox';
import { useApi } from "../../../hooks/useApi";
import Skeleton from "react-loading-skeleton";
import { EmptyList, Error } from "../../../components";
// import { FaLongArrowAltDown } from "react-icons/fa";
import Cookies from "js-cookie";

const VoteInCat = () => {
  // const { t } = useTranslation();
  const { state } = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  // const [sorting, setSorting] = useState(null);
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("vote");
  const [currentPage, setCurrentPage] = useState(1);
  const [votesList, setVotesList] = useState([]);
  useEffect(() => {
    !state?.data && navigate("/votes/categories");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data]);

  // get votes
  const {
    data: votes,
    loading: votesLoading,
    onRequest: onGetVotes,
    error: votesError,
  } = useApi(
    `/api/viewVotes?current_page=${currentPage}&per_page=2&category_id=${slug}`,
    "get"
  );

  useEffect(() => {
    if (slug) onGetVotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, currentPage]);

  // const handelSorting = () => {
  //   if (sorting === null) {
  //     setSorting("asc");
  //   } else if (sorting === "asc") {
  //     setSorting("desc");
  //   } else {
  //     setSorting(null);
  //   }
  // }

  useEffect(() => {
    if (votes?.data) {
      setVotesList((prevList) => [...prevList, ...votes.data]);
    }
  }, [votes?.data]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (votesList.length < votes?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [votesList.length, votes?.count]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  const handleUpdateCategories = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة الأولى
    setVotesList([]); // تفريغ القائمة الحالية
    onGetVotes(); // جلب البيانات من جديد
  };
  return (
    <>
      <PageHeader title={state?.data || "Unknown"} backHref={"/votes/categories"} />
      <div className={`container`}>
        <div className={styles.page__header}>
          {permission && (
            <Link to={`/votes/add/${slug}`} className={styles.header__btn}>
              {'اضافة تصويت جديد'} <IoMdAdd />
            </Link>
          )}
          {/* <button
            onClick={handelSorting}
            className={`${styles.header__btn} ${sorting === null
                ? styles.off
                : sorting === "asc"
                  ? styles.increase
                  : styles.decrease
              }`}
          >
            <FaLongArrowAltDown />
            {t("sortingByName")}
          </button> */}
        </div>
        {votesError ? (
          <Error msg={votesError?.message} />
        ) : votesLoading ? (
          <div className={styles.list}>
            {Array(5)
              ?.fill("")
              ?.map((_, i) => (
                <Skeleton
                  key={i}
                  width="100%"
                  height="213px"
                  borderRadius="4px"
                />
              ))}
          </div>
        ) : !votes?.data?.length ? (
              <EmptyList text={`لا يوجد أي تصويتات في الوقت الراهن${'، الآن يمكنك إضافة تصويت جديد'} `} />
        ) : (
          <div className={styles.list}>
            {votesList?.map((vote) => (
              <VoteBox
                key={vote?.id}
                vote={vote}
                onGetList={handleUpdateCategories}
                permission={permission}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default VoteInCat