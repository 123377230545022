import React, { useContext, useState } from 'react';
import { MainButton, Popup } from '../../../../components';
import styles from './.module.scss';
import { ModalContext } from '../../../../context/ModalContext';
import { useApi } from '../../../../hooks/useApi';
import { useNavigate } from 'react-router-dom';

const CancelModal = ({ id, category_id, bookType }) => {
  const { setIdModal } = useContext(ModalContext);
  const navigate = useNavigate();
  const [status, setStatus] = useState('')
  // Book product:=
  const {
    loading: submitting,
    onRequest: onBookProduct,
  } = useApi("/api/bookAction", "post");

  const onSubmit = async (selectedStatus) => {
    setStatus(selectedStatus)
    const res = await onBookProduct({
      activity_id: id,
      type: bookType,
      status: selectedStatus,
    });
    if (res?.success) {
      setIdModal("");
      navigate(`/activities/categories/${category_id}`)
    }
  }

  return (
    <Popup>
      <p className={styles.text}>
        هل انت متأكد من الغاء الحجز
      </p>
      <div className={styles.btns}>
        <MainButton
          onClick={() => onSubmit("2")}  // عند الضغط على مشاهد: type = 2, status = 2
          style={{ backgroundColor: "#e95858" }}
          disabled={status=== '2' && submitting}
          loading={status=== '2' && submitting}
        >
          نعم
        </MainButton>
        <MainButton
          onClick={() => setIdModal("")}
        >
          عودة
        </MainButton>
      </div>
    </Popup>
  );
};

export default CancelModal;
