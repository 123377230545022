import React, { useContext } from 'react';
import styles from './.module.scss';
import DefaultCover from "../../../../assets/DefaultCover.png";
import { useNavigate } from 'react-router-dom';
import { DeleteModal } from '../../../../components';
import { ModalContext } from '../../../../context/ModalContext';
import { DeleteIcon, EditIcon } from '../../../../icons';

const ActivateBox = ({ activate, onGetList, permission,  }) => {

  const { idModal, setIdModal } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <>
      <div type="button" className={styles.box}>
        <div
          className={styles.image__box}
          onClick={(e) => {
            navigate(`/activate/${activate?.id}`, { state: { data: activate } });
          }}
        >
          <img
            src={activate?.image || DefaultCover}
            alt={"activate_image"}
            className={styles.img}
          />
        </div>
        <h4
          className={styles.title}
          onClick={(e) => {
            navigate(`/activate/${activate?.id}`, { state: { data: activate } });
          }}
        >
          {activate?.title}
        </h4>
        {permission &&
          <>
            <div className={styles.btns}>
              <button
                style={{ display: "flex" }}
                onClick={() => {
                  navigate(`/activities/${activate?.id}/edit`, { state: { data: activate } });
                }}
              >
                <EditIcon />
              </button>
              <button
                type="button"
                className={styles.delete__btn}
                onClick={() => setIdModal(`delete-news-${activate?.id}`)}
              >
                <DeleteIcon />
              </button>
            </div>
          </>}
      </div>
      {idModal === `delete-news-${activate?.id}` && (
        <DeleteModal
          body={{
            activity_id: activate?.id,
          }}
          endpoint="deleteActivity"
          title="هل أنت متأكد أنك تريد حذف هذا النشاط"
          getList={onGetList}
        />
      )}
    </>
  );
};

export default ActivateBox