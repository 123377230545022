import React, { useEffect, useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { Box, Button, CardMedia, Checkbox, Typography } from '@mui/material';
import { useApi } from '../../../hooks/useApi';
import DefaultCover from "../../../assets/DefaultCover.png";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ActionUsersToVote = ({ permission, slug, styles, state, isBeforeStartDate }) => {
    console.log("🚀 ~ ActionUsersToVote ~ state:", state)
    const navigate = useNavigate();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const { loading, onRequest: addUser } = useApi(`/api/addVotetoUsers`, "post");
    const { data: users, onRequest: onGetUsers } = useApi(`/api/viewVoteUsers?vote_id=${slug}`, "get");

    useEffect(() => {
        onGetUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckboxChange = (userId) => {
        setSelectedUsers((prevSelected) =>
            prevSelected.includes(userId)
                ? prevSelected.filter((id) => id !== userId)
                : [...prevSelected, userId]
        );
    };

    const handleImageClick = (userId) => {
        handleCheckboxChange(userId);
    };

    const handleSubmit = async () => {
        if (isBeforeStartDate) {
            toast.warning('هذا التصويت لم يبدأ بعد.');
            return;
        }

        const requestData = {
            vote_id: +slug, // assuming slug is the vote_id
            addVotetoUsers: selectedUsers,
        };

        try {
            const res = selectedUsers.length > 0 ? await addUser(requestData) : toast.error('يرجى اختيار مرشح');
            console.log("🚀 ~ handleSubmit ~ res:", res)
            if (res?.success) {
                // Handle success (e.g., navigate, show a success message, clear the form, etc.)
                setSelectedUsers([]);
                navigate(`/votes/categories/${state?.data?.category_id}`); // Replace with the correct route
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="container">
            <div className={styles.page__header}>
                {users && 
                <Button
                disabled={loading || isBeforeStartDate} // Disable the button if the vote hasn't started yet
                    sx={{
                        position: 'fixed',
                        left: '50%',
                        bottom: '5%',
                        transform: 'translateX(-50%)',
                        zIndex: 99,
                        backgroundColor: isBeforeStartDate ? '#cccccc' : '#26C0FF',
                        color: '#fff',
                        ':hover': { backgroundColor: '#26C0FF', color: '#fff' },
                    }}
                    className={styles.header__btn}
                    onClick={handleSubmit}
                >
                    {isBeforeStartDate  ? 'هذا التصويت لم يبدأ بعد' : 'تصويت'} <IoMdAdd />
                </Button>
            }
                {/* {permission && (
                    <Button
                        disabled={loading || isBeforeStartDate} // Disable the button if the vote hasn't started yet
                        sx={{
                            position: 'fixed',
                            left: '50%',
                            bottom: '5%',
                            transform: 'translateX(-50%)',
                            zIndex: 99,
                            backgroundColor: isBeforeStartDate ? '#cccccc' : '#26C0FF',
                            color: '#fff',
                            ':hover': { backgroundColor: '#26C0FF', color: '#fff' },
                        }}
                        className={styles.header__btn}
                        onClick={handleSubmit}
                    >
                        {isBeforeStartDate ? 'هذا التصويت لم يبدأ بعد' :'تصويت'} <IoMdAdd />
                    </Button>
                )} */}
            </div>
            <div className={styles.list}>
                {users?.data?.map((user) => (
                    <Box sx={{ borderRadius: '6px', backgroundColor: '#F8F8F8' }} key={user?.id} className={styles.box}>
                        <div onClick={() => !isBeforeStartDate && handleImageClick(user?.user?.id)} className={styles.image__box}>
                            <CardMedia
                                component={'img'}
                                src={user?.user?.profile_picture || DefaultCover}
                                alt={"user_image"}
                                className={styles.img}
                                sx={{ borderRadius: '50%', objectFit: 'cover', width: 75, height: 75, m: '8px auto' }}
                            />
                        </div>
                        <Box>
                            <h4 className={styles.title}>
                                {user?.user?.name}
                            </h4>
                            <Typography sx={{ px: 2 }}>
                                {user?.user?.second_name}
                            </Typography>
                            <Typography sx={{ px: 2 }}>
                                {user?.user?.date_of_birth}
                            </Typography>
                            <Typography sx={{ px: 2 }}>
                                {user?.description}
                            </Typography>
                        </Box>
                        {/* {permission && !isBeforeStartDate && (
                            <>
                                <Checkbox
                                    checked={selectedUsers.includes(user?.user?.id)}
                                    onChange={() => handleCheckboxChange(user?.user?.id)}
                                    color="primary"
                                    className={styles.btns}
                                    disabled={isBeforeStartDate} // Disable the checkbox if the vote hasn't started yet
                                />
                            </>
                        )} */}
                        <Checkbox
                            checked={selectedUsers.includes(user?.user?.id)}
                            onChange={() => handleCheckboxChange(user?.user?.id)}
                            color="primary"
                            className={styles.btns}
                            disabled={isBeforeStartDate} // Disable the checkbox if the vote hasn't started yet
                        />
                    </Box>
                ))}
            </div>
        </div>
    );
};

export default ActionUsersToVote;
