import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageHeader } from "../../../layout";
import { useTranslation } from "react-i18next";
import { EmptyList, Error, MainSlider } from "../../../components";
import styles from "./.module.scss";
import { IoMdAdd } from "react-icons/io";
import { useApi } from "../../../hooks/useApi";
import Skeleton from "react-loading-skeleton";
import Cookies from "js-cookie";
import CategoryBox from "../_components/CategoryBox";
import CategoryForm from "../_components/CategoryForm";
import { ModalContext } from "../../../context/ModalContext";

const ActivitiesCategories = () => {
  const { t } = useTranslation();

    const { setIdModal, idModal } = useContext(ModalContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [activateList, setActivateList] = useState([]);
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("activity");

  // get Liquidation slider:=
  const {
    data: slider,
    loading: sliderLoading,
    onRequest: onGetSlider,
    error: SliderError,
  } = useApi("/api/viewActivitySlider", "get");

  // get activate
  const {
    data: activate,
    error: activateError,
    loading: activateLoading,
    onRequest: onGetActivate,
  } = useApi(`/api/viewActivityCategory?current_page=${currentPage}&per_page=15`, "get");

  useEffect(() => {
    onGetSlider();
    onGetActivate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (activate?.data) {
      setActivateList((prevList) => [...prevList, ...activate.data]);
    }
  }, [activate?.data]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (activateList.length < activate?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [activateList.length, activate?.count]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  
  const handleUpdateCategories = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة الأولى
    setActivateList([]); // تفريغ القائمة الحالية
    onGetActivate(); // جلب البيانات من جديد
  };
  return (
    <div className={`${styles.page} container`}>
      <PageHeader title={t("الأنشطة والفاعليات")} />
      {SliderError ? (
        <Error msg={SliderError?.message} />
      ) : (
        <MainSlider
          loading={sliderLoading}
          images={slider?.data?.map((item) => item?.image) || []}
        />
      )}
      {permission && (
        <>
          <button
            className={styles.add__btn}
            onClick={() => setIdModal("add-new-category")}
          >
            إضافة فئة جديدة <IoMdAdd />
          </button>
        </>
      )}
      {activateLoading ? (
        <div className={styles.list}>
          {Array(5)
            ?.fill("")
            ?.map((_, i) => (
              <Skeleton
                key={i}
                width="100%"
                height="213px"
                borderRadius="4px"
              />
            ))}
        </div>
      ) : activateError ? (
        <Error msg={activateError?.message} />
        ) : activate?.data?.length ?(
        <div className={styles.list}>
              {activateList?.map((activate) => (
            <CategoryBox
              key={activate?.id}
              activate={activate}
              onGetCategories={handleUpdateCategories}
            />
          ) )}
        </div>
      ) : (
        <EmptyList text="لا توجد فئات تصويت الآن، يمكنك إضافة فئة جديدة" />
      )}
    {idModal === "add-new-category" && (
        <CategoryForm onGetList={onGetActivate} />
      )}
    </div>
  );
};

export default ActivitiesCategories;
