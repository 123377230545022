import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../hooks/useApi';
import { PageHeader } from '../../../layout';
import styles from './.module.scss';
import { EmptyList, Error, MainSlider } from '../../../components';
import Box from '../_components/Box';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import Cookies from 'js-cookie';

const Congratulations = () => {
  const { t } = useTranslation();
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("congratulte");
  const [currentPage, setCurrentPage] = useState(1);
  const [congratulteList, setCngratulteList] = useState([]);

  // جلب بيانات السلايدر
  const {
    data: slider,
    loading: sliderLoading,
    onRequest: onGetSlider,
    error: SliderError,
  } = useApi("api/viewCongratulateSlider?current_page=1&per_page=10000", "get");

  // جلب التهاني
  const {
    data: congratulations,
    loading: congratulationsLoading,
    onRequest: onGetCongratulations,
  } = useApi(`/api/viewCongratulate?current_page=${currentPage}&per_page=15`, "get");

  // استدعاء الدوال عند تحميل الصفحة
  useEffect(() => {
    onGetSlider();
    onGetCongratulations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // تحديث قائمة التهاني عند جلب البيانات للصفحات التالية فقط
  useEffect(() => {
    if (congratulations?.data) {
      setCngratulteList((prevList) => [...prevList, ...congratulations.data]);
    }
  }, [congratulations?.data]);

  // دالة التمرير لإضافة بيانات جديدة عند الوصول لنهاية الصفحة
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (congratulteList.length < congratulations?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [congratulteList.length, congratulations?.count]);

  // إضافة أو إزالة مستمع التمرير
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // دالة لتحديث القائمة عند الإضافة، التعديل، أو الحذف
  const handleUpdateCongratulations = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة للأولى
    setCngratulteList([]); // تفريغ القائمة
    onGetCongratulations(); // جلب البيانات من البداية
    onGetSlider(); // تحديث السلايدر إذا كان مطلوب
  };

  return (
    <>
      <PageHeader title={t("التهاني")} backHref="/" />
      <div className={`${styles.page} container`}>
        {SliderError ? (
          <Error msg={SliderError?.message} />
        ) : (
          <MainSlider
            loading={sliderLoading}
            images={slider?.data?.filter((item) => item?.image)}
            type="congratulations"
          />
        )}
        {permission && (
          <Link to={`/congratulations/add`} className={styles.add__btn}>
            {t("AddNewCongratulation")} <IoMdAdd />
          </Link>
        )}
        {/* قائمة التهاني */}
        {congratulationsLoading ? (
          <div className={styles.list}>
            {Array(4)
              .fill("")
              .map((_, i) => (
                <Skeleton
                  key={i}
                  width="100%"
                  height="213px"
                  borderRadius="4px"
                />
              ))}
          </div>
        ) : congratulteList.length ? (
          <div className={styles.list}>
            {congratulteList.map((diwaniya) => (
              <Box
                key={diwaniya?.id}
                diwaniya={diwaniya}
                onGetList={handleUpdateCongratulations} // التحديث عند الإضافة أو التعديل أو الحذف
                permission={permission}
              />
            ))}
          </div>
        ) : (
          <EmptyList text="لا يوجد أي تهنئة، الآن يمكنك إضافة تهنئتك" />
        )}
      </div>
    </>
  );
};

export default Congratulations;
