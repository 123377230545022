import React from "react";
import AddWifeForm from "./AddWifeForm";
import WifeBox from "./WifeBox";
import styles from './.module.scss';

const WifesSection = ({ control, formData, gender }) => {
  const marriages = formData?.marry || [];

  const shouldShowAddWifeForm =
    (gender === 'male' && marriages.length < 4) ||
    (gender === 'female' && marriages.length < 1);

  console.log("Gender:", gender);
  console.log("Number of Marriages:", marriages.length);
  console.log("Should show AddWifeForm:", shouldShowAddWifeForm);

  return (
    <>
      {shouldShowAddWifeForm && (
        <AddWifeForm
          register={() => { }}
          errors={null}
          control={control}
          gender={gender}
        />
      )}
      {formData?.marry?.length ? (
        <div className={styles.list}>
          {formData?.marry?.map((item, i) => (
            <WifeBox
              key={item?.id}
              item={{ ...item }}
              control={control}
              i={i}
              gender={gender}
            />
          ))}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default WifesSection;
