import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from '../hooks/useApi';
import Cookies from "js-cookie";

const NotificationVote = () => {
    const token = Cookies.get("token");
    const { onRequest: onRequestNotVote, data: dataNotVote } = useApi("/api/vote_notification", "get");
    const [lastNotification, setLastNotification] = useState(null);

    useEffect(() => {
        if (token) {
            onRequestNotVote();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (dataNotVote && dataNotVote.data.length > 0) {
            const latestNotification = dataNotVote.data[dataNotVote.data.length - 1];

            if (!lastNotification || lastNotification.id !== latestNotification.id) {
                toast.success(`لديك ترشيح جديد: ${latestNotification.title}`);
                setLastNotification(latestNotification);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataNotVote]);

    return null; // هذا المكون لا يحتاج إلى عرض أي شيء
};

export default NotificationVote;
