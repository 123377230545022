import React, { useState } from 'react';
// import { Controller, useFieldArray } from 'react-hook-form';
import { DatePicker, MainButton, MainInput, UploadImages} from '../../components';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
// import { FaTrashAlt } from "react-icons/fa";
import styles from './AddNewVote/.module.scss';
import { Controller } from 'react-hook-form';

const VoteForm = ({
  onSubmit,
  control,
  register,
  formData,
  errors,
  submitting,
  edit,
}) => {
  const { t } = useTranslation();
  // const [openMap, setOpenMap] = useState(false);
  const [diwaniyaTime, setDiwaniyaTime] = useState('1');
  
  const [showVoteAge, setShowVoteAge] = useState('2');
  const [showResultTo, setShowResultTo] = useState('2');

  const options = [
    {
      value: "1",
      label: 'اجمالي عدد المصوتين',
    },
    {
      value: "2",
      label: 'انتهاء تاريخ المنافسة',
    },
    {
      value: "3",
      label: 'كلاهما',
    }
  ];
  const optionsShowResultTo = [
    {
      value: "2",
      label: 'عرض للأدمن فقط',
    },
    {
      value: "1",
      label: 'عرض للجميع',
    },
  ];
  const optionsShowVoteAge = [
    {
      value: "1",
      label: 'عرض للكل',
    },
    {
      value: "2",
      label: 'تحديد عمر معين',
    },
  ];

  return (
    <form onSubmit={onSubmit} className={styles.form__content}>
      {/* Name */}
      {/* <div className={styles.dividing}>
        <span className={styles.text}>المعلومات الأساسية</span>
      </div> */}
      <MainInput
        register={register}
        placeholder={'اسم التصويت'}
        label="اسم التصويت"
        type="text"
        name="title"
        value={formData?.name}
        error={errors?.name?.message}
        required
      />
      {/* start Date */}
      <DatePicker
        control={control}
        name="start_date"
        error={errors?.start_date?.message}
        date={formData?.start_date}
        defaultDate
        type='بداية التصويت'
      />
      {/* end_date */}
      <DatePicker
        control={control}
        name="end_date"
        error={errors?.end_date?.message}
        date={formData?.end_date}
        defaultDate
        type='نهاية التصويت'
      />
      <div style={{ position: "relative" }}>
        <Controller
          name="finish_with"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              value={options.find(option => option.value === field.value) || null}
              options={options}
              styles={{
                control: (styles) => ({
                  ...styles,
                  height: "64px",
                  borderRadius: "8px",
                  borderColor: errors?.finish_with ? "#E92121" : "#DEDEDE",
                  outline: "none !important",
                  borderWidth: "1px",
                }),
              }}
              placeholder="طريقة انتهاء التصويت"
              onChange={(selectedOption) => {
                field.onChange(selectedOption.value);
                setDiwaniyaTime(selectedOption);
              }}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          )}
        />
      </div>
      <MainInput
        register={register}
        placeholder={'عدد الفائزين الذي تقبله المنافسة'}
        label="عدد الفائزين الذي تقبله المنافسة"
        type="string"
        name="win_count"
        value={formData?.win_count}
        error={errors?.name?.message}
        required
      />
      <MainInput
        register={register}
        placeholder={'عدد الأصوات المسموحة لكل شخص'}
        label="عدد الأصوات المسموحة لكل شخص"
        type="string"
        name="vote_user_count"
        value={formData?.vote_user_count}
        error={errors?.name?.message}
        required
      />
      <MainInput
        register={register}
        placeholder={'اجمالي عدد المصوتين.'}
        label="اجمالي عدد المصوتين."
        type="string"
        name="vote_total_user_count"
        value={formData?.vote_total_user_count}
        error={errors?.name?.message}
        required
      />
      <div style={{ position: "relative" }}>

        <Controller
          name="view_vote_to"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              value={optionsShowVoteAge.find(option => option.value === field.value) || null}
              options={optionsShowVoteAge}
              styles={{
                control: (styles) => ({
                  ...styles,
                  height: "64px",
                  borderRadius: "8px",
                  borderColor: errors?.view_vote_to ? "#E92121" : "#DEDEDE",
                  outline: "none !important",
                  borderWidth: "1px",
                }),
              }}
              placeholder="عرض الانتخابات"
              onChange={(selectedOption) => {
                field.onChange(selectedOption.value);
                setShowVoteAge(selectedOption);
              }}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          )}
        />



      </div>
      {
        (showVoteAge?.value === '2' || showVoteAge === '2') && <MainInput
          register={register}
          placeholder={'تحديد العمر المسموح '}
          label="تحديد العمر المسموح "
          type="string"
          name="min_voter_age"
          value={formData?.min_voter_age}
          error={errors?.name?.message}
        />
      }
      <div style={{ position: "relative" }}>
        <Controller
          name="view_result_to"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              value={optionsShowResultTo.find(option => option.value === field.value) || null}
              options={optionsShowResultTo}
              styles={{
                control: (styles) => ({
                  ...styles,
                  height: "64px",
                  borderRadius: "8px",
                  borderColor: errors?.view_result_to ? "#E92121" : "#DEDEDE",
                  outline: "none !important",
                  borderWidth: "1px",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: errors?.view_result_to ? "#E92121" : "",
                }),
              }}
              placeholder="عرض النتائج:"
              onChange={(selectedOption) => {
                field.onChange(selectedOption.value);
                setShowResultTo(selectedOption);
              }}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          )}
        />
      </div>
      <UploadImages
        images={formData?.images || []}
        error={errors?.images?.message}
        control={control}
        name="images"
        single
      />
      <MainButton loading={submitting} disabled={submitting} type="submit">
        {t(edit ? "edit" : "add")}
      </MainButton>
    </form>
  );
};

export default VoteForm