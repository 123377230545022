import React, { useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { PageHeader } from '../../../layout';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../../hooks/useApi";
import { toast } from "react-toastify";
import VoteForm from "../VoteForm";
import uploadFile from "../../../utils/uploadImages";
    
const EditVote = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const location = useLocation();
  const vote = location?.state?.data;
  console.log("🚀 ~ EditVote ~ vote:", vote)
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!vote) navigate("/votes/categories");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vote]);

  // ADD SCHEMA
  const schema = yup.object({
    title: yup.string("").required(t("errors.required")),
    start_date: yup.string("").required(t("errors.required")),
    end_date: yup.string().required(t("errors.required")),
    finish_with: yup.string().required(t("errors.required")),
    win_count: yup.string("").required(t("errors.required")),
    vote_user_count: yup.string("").required(t("errors.required")),
    vote_total_user_count: yup.string("").required(t("errors.required")),
    view_vote_to: yup.string("").required(t("errors.required")),
    min_voter_age: yup.string(""),
    view_result_to: yup.string("").required(t("errors.required")),
    images: yup.array().min(1, "at least 1 item").required("image is required"),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: vote?.title,
      start_date: vote?.start_date,
      end_date: vote?.end_date,
      finish_with: vote?.finish_with,
      win_count: vote?.win_count,
      vote_user_count: vote?.vote_user_count,
      vote_total_user_count: vote?.vote_total_user_count,
      view_vote_to: vote?.view_vote_to,
      min_voter_age: vote?.min_voter_age || '',
      view_result_to: vote?.view_result_to,
      category_id: vote?.category_id,
      images: [vote?.image],
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  // send vote base-info
  const { onRequest: EditVote } = useApi("/api/editVote", "post");

  const onSubmit = async (e) => {

    setSubmitting(true);

    // Base-info
    const body = {
      vote_id: slug,
      title: e.title,
      start_date: e.start_date,
      end_date: e.end_date,
      finish_with: e.finish_with,
      win_count: e.win_count,
      vote_user_count: e.vote_user_count,
      vote_total_user_count: e.vote_total_user_count,
      view_vote_to: e.view_vote_to,
      min_voter_age: e.min_voter_age || '',
      view_result_to: e.view_result_to,
      category_id: vote?.category_id,
    };

    try {
      const res = await EditVote(body, "IGNOREMESSAGE");
      
      if (res?.success) {
        await uploadFile({
          images: e?.images,
          category_type: "votes",
          category_id: res?.data?.id,
        });
        toast.success("تمت العملية بنجاح");
        navigate(`/votes/categories`);
        setSubmitting(false);
      }
      
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
    setSubmitting(false);
  };

  return (
    <>
      <PageHeader title={`${t("edit")} ${vote?.title}`} />
      <div className="container">
        <VoteForm
          onSubmit={handleSubmit((e) => onSubmit(e))}
          control={control}
          register={register}
          formData={watch()}
          errors={errors}
          submitting={submitting}
          edit
        />
      </div>
    </>
  );
};

export default EditVote;