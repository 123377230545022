import React, { useCallback, useEffect, useState } from 'react'
import { useApi } from '../../../hooks/useApi';
import { EmptyList, Error, MainSlider } from '../../../components';
import { PageHeader } from '../../../layout';
import { Link } from 'react-router-dom';
import { IoMdAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import styles from './.module.scss';
import Skeleton from 'react-loading-skeleton';
import Box from '../_components/Box';
import Cookies from 'js-cookie';

const News = () => {
  const { t } = useTranslation();
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("news");
  const [currentPage, setCurrentPage] = useState(1);
  const [newsList, setNewsList] = useState([]);
  // get news slider:=
  const {
    data: slider,
    loading: sliderLoading,
    onRequest: onGetSlider,
    error: SliderError,
  } = useApi("/api/viewNewsSlider?current_page=1&per_page=10000", "get");

  // get news
  const {
    data: news,
    error: newsError,
    loading: newsLoading,
    onRequest: onGetNews,
  } = useApi(`/api/viewNews?current_page=${currentPage}&per_page=15`, "get");

  useEffect(() => {
    onGetSlider();
    onGetNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (news?.data) {
      setNewsList((prevList) => [...prevList, ...news.data]);
    }
  }, [news?.data]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (newsList.length < news?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [newsList.length, news?.count]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  const handleUpdateCategories = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة الأولى
    setNewsList([]); // تفريغ القائمة الحالية
    onGetNews(); // جلب البيانات من جديد
  };
  return (
    <>
      <PageHeader title={t("news")} backHref="/" />
      <div className={`${styles.page} container`}>
        {SliderError ? (
          <Error msg={SliderError?.message} />
        ) : (
          <MainSlider
            loading={sliderLoading}
            images={slider?.data?.filter((item) => {
              if (item?.image) {
                return {
                  image: item?.image,
                  ...item,
                };
              }
            })}
            type="news"
          />
        )}
        {permission && <Link to={`/news/add`} className={styles.add__btn}>
          {t("AddNewNews")} <IoMdAdd />
        </Link>}
        {newsLoading ? (
          <div className={styles.list}>
            {Array(5)
              ?.fill("")
              ?.map((_, i) => (
                <Skeleton
                  key={i}
                  width="100%"
                  height="213px"
                  borderRadius="4px"
                />
              ))}
          </div>
        ) : newsError ? (
          <Error msg={newsLoading?.message} />
        ) : news?.data?.length ? (
          <div className={styles.list}>
                {newsList?.map((singleNew) => (
              <Box
                permission={permission}
                key={singleNew?.id}
                diwaniya={singleNew}
                    onGetList={handleUpdateCategories}
              />
            ))}
          </div>
        ) : (
          <EmptyList text="لا يوجد أي أخبار في الوقت الراهن، الآن يمكنك إضافة الأخبار" />
        )}
      </div>
    </>
  );
}

export default News