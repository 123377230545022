import Cookies from "js-cookie";

const uploadArchiveFile = async (body) => {
  const token = Cookies.get("token");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);  // تعديل إلى "Bearer"

  const formdata = new FormData();

  console.log("🚀 ~ uploadArchiveFile ~ body.file[0]:", body.file)
  // التعامل مع الملفات بناءً على نوعها
  if (body.type === 1 && body.file && body.file.length > 0) {
    // نوع 1 للصورة
    formdata.append("file", body.file[0]);
  } else if (body.type === 2 && body.file && body.file) {
    // نوع 2 لملف PDF أو أنواع أخرى
    formdata.append("file", body.file);
  }
  else {
    formdata.append("file", '');
    // في حال لم يتم تزويد ملف أو رابط حسب نوع البيانات المطلوب
    console.error("No valid file or URL provided based on the type.");
    return { success: false, message: "رجاء اختيار رابط او ملف" };
  }
  formdata.append("url", body.url);


  formdata.append("archive_id", body.archive_id.toString());
  formdata.append("type", body.type.toString());

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  try {
    const response = await fetch("https://fasterlink.me/api/addArchiveFile", requestOptions);
    const result = await response.text();
    console.log(result);
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default uploadArchiveFile;
