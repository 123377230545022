import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./.module.scss";
import Skeleton from "react-loading-skeleton";
import { IoMdAdd } from "react-icons/io";
import Cookies from "js-cookie";
import { PageHeader } from "../../../layout";
import { EmptyList, Error, MainSlider } from "../../../components";
import { useApi } from "../../../hooks/useApi";
import { ModalContext } from "../../../context/ModalContext";
import CategoryForm from "../_components/CategoryForm";
import CategoryBox from "../_components/CategoryBox";
import { useNavigate } from "react-router-dom";
import { Badge, Box } from "@mui/material";
import { IoNotifications } from "react-icons/io5";

const VotesCategories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setIdModal, idModal } = useContext(ModalContext);
  const { userPermission } = JSON.parse(Cookies.get("user"));
  const permission = userPermission.includes("vote");
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesList, setCategoriesList] = useState([]);

  // جلب البيانات للسلايدر
  const {
    data: slider,
    loading: sliderLoading,
    onRequest: onGetSlider,
    error: SliderError,
  } = useApi("/api/viewVoteSlider", "get");

  // جلب بيانات الفئات
  const {
    data: categories,
    loading: categoriesLoading,
    onRequest: onGetCategories,
    error: categoryError,
  } = useApi(`/api/viewVoteCategory?current_page=${currentPage}&per_page=15`, "get");

  // جلب إشعارات التصويت
  const { onRequest: onRequestNotVote, data: dataNotVote } = useApi("/api/vote_notification", "get");

  // استدعاء الدوال عند تحميل الصفحة
  useEffect(() => {
    onGetSlider();
    onGetCategories();
    onRequestNotVote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // تحديث قائمة الفئات عند جلب البيانات للصفحات التالية فقط
  useEffect(() => {
    if (categories?.data) {
      setCategoriesList((prevList) => [...prevList, ...categories.data]);
    }
  }, [categories?.data]);

  // دالة التمرير لإضافة بيانات جديدة عند الوصول لنهاية الصفحة
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (categoriesList.length < categories?.count) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [categoriesList.length, categories?.count]);

  // إضافة أو إزالة مستمع التمرير
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // دالة لتحديث الفئات عند الإضافة، التعديل، أو الحذف
  const handleUpdateCategories = () => {
    setCurrentPage(1); // إعادة تعيين الصفحة للأولى
    setCategoriesList([]); // تفريغ القائمة
    onGetCategories(); // جلب البيانات من البداية
    onGetSlider(); // تحديث السلايدر إذا كان مطلوب
  };

  return (
    <>
      <PageHeader title={t("تصويت")} backHref="/" />
      <div className={`${styles.page} container`}>
        {SliderError ? (
          <Error msg={SliderError?.message} />
        ) : (
          <MainSlider
            loading={sliderLoading}
            images={slider?.data?.filter((item) => item?.image)}
            type="vote"
          />
        )}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {permission && (
            <>
              <button
                className={styles.add__btn}
                onClick={() => setIdModal("add-new-category")}
              >
                إضافة فئة جديدة <IoMdAdd />
              </button>
            </>
          )}
          <button
            className={styles.add__btn}
            onClick={() => navigate("/vote/notification")}
          >
            طلبات الأنضمام للترشيح
            <Badge
              badgeContent={dataNotVote?.data?.length}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <IoNotifications />
            </Badge>
          </button>
        </Box>
        <div className={styles.list}>
          {categoriesLoading ? (
            Array(4)
              .fill("")
              .map((_, i) => (
                <Skeleton
                  width="100%"
                  height="87px"
                  borderRadius="8px"
                  key={i}
                />
              ))
          ) : categoryError ? (
            <Error msg={categoryError?.message} />
          ) : categoriesList.length ? (
            categoriesList.map((vote, i) => (
              <CategoryBox
                key={i}
                vote={vote}
                onGetCategories={handleUpdateCategories}
              />
            ))
          ) : (
            <EmptyList text="لا توجد فئات تصويت الآن، يمكنك إضافة فئة جديدة" />
          )}
        </div>
      </div>
      {idModal === "add-new-category" && (
        <CategoryForm onGetList={handleUpdateCategories} />
      )}
    </>
  );
};

export default VotesCategories;
