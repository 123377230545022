import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Activities, AddActivities, AddArchive, AddCongratulations, AddLiquidation, AddNewDiwaniya, AddNews, AddOccasion, AddToFamilyTree, Archives, ArchivesCategories, Congratulations, DiwaniyaDetails, Diwaniyas, EditActivities, EditCongratulations, EditDiwaniya, EditLiquidation, EditNews, EditOccasion, EditProfile, ForgetPassword, FreeBusiness, Home, Inquiries, Liquidation, LiquidationDetails, Login, NewDetails, News, Notifications, OccasionDetails, Occasions, Profile, Register, SingleCongratulation, SingleDiwaniya, SingleFreeBusiness, TechSupport, UserFreelanceRequest, VotesCategories, WelcomePage } from './pages'
import { PagesGuard } from './components/AuthGuard';
import "react-loading-skeleton/dist/skeleton.css";
import "react-quill/dist/quill.snow.css";
import Cookies from 'js-cookie';
import MainLayout from './layout/MainLayout';
import VoteInCat from './pages/Votes/VoteInCat';
import AddNewVote from './pages/Votes/AddNewVote';
import EditVote from './pages/Votes/EditVote';
import SingleVote from './pages/Votes/SingleVote';
import VoteNotification from './pages/Votes/VoteNotification';
import NotificationVote from './utils/NotificationVote';
import ActivateDetails from './pages/Activities/ActivateDetails/ActivateDetails';
import ActivitiesCategories from './pages/Activities/ActivitiesCategories';

const App = () => {
  const user = Cookies.get("user");
  const default_page = user && JSON.parse(Cookies.get("user"));
  const navigate = useNavigate();
  const [show, setShow] = useState(default_page?.default_page === 1 ? true : false);

  useEffect(() => {
    if (default_page === 0) {
      navigate("/welcome")
    }
    Cookies.set("visitHome", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  return (
    <>
      <NotificationVote />
      <Routes>
        <Route path="" element={<PagesGuard />}>
          <Route path="" element={<MainLayout show={show} setShow={setShow} />}>
            <Route path="/" element={<Home show={show} setShow={setShow} />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route path="/inquiries" element={<Inquiries />} />
            <Route path="/free-business" element={<FreeBusiness />} />
            <Route
              path="/free-business/:slug"
              element={<SingleFreeBusiness />}
            />
            <Route
              path="/free-business/:slug/request"
              element={<UserFreelanceRequest />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/edit" element={<EditProfile />} />
            {/* ========== diwaniyas ========== */}
            <Route path="/diwaniyas" element={<Diwaniyas />} />
            <Route path="/diwaniyas/:slug" element={<SingleDiwaniya />} />
            <Route
              path="/diwaniyas/:slug/:slug"
              element={<DiwaniyaDetails />}
            />
            <Route path="/diwaniyas/add/:slug" element={<AddNewDiwaniya />} />
            <Route path="/diwaniyas/edit/:slug" element={<EditDiwaniya />} />
            {/* =============== Occasions ============== */}
            <Route path="/occasions" element={<Occasions />} />
            <Route path="/occasions/:slug" element={<OccasionDetails />} />
            <Route path="/occasions/add/:slug" element={<AddOccasion />} />
            <Route path="/occasions/edit/:slug" element={<EditOccasion />} />
            {/* ================ Congratulations ========= */}
            <Route path="/congratulations" element={<Congratulations />} />
            <Route
              path="/congratulations/:slug"
              element={<SingleCongratulation />}
            />
            <Route
              path="/congratulations/add"
              element={<AddCongratulations />}
            />
            <Route
              path="/congratulations/edit"
              element={<EditCongratulations />}
            />
            {/* ================ News ===================== */}
            <Route path="/news" element={<News />} />
            <Route path="/news/:slug" element={<NewDetails />} />
            <Route path="/news/add" element={<AddNews />} />
            <Route path="/news/edit" element={<EditNews />} />
            {/* ================ Liquidation ===================== */}
            <Route path="/liquidation" element={<Liquidation />} />
            <Route path="/liquidation/:slug" element={<LiquidationDetails />} />
            <Route path="/liquidation/add" element={<AddLiquidation />} />
            <Route
              path="/liquidation/:slug/edit"
              element={<EditLiquidation />}
            />
            {/* ================ Archives ===================== */}
            <Route path="/archives" element={<ArchivesCategories />} />
            <Route path="/archives/:slug" element={<Archives />} />
            <Route path="/archives/:slug/add" element={<AddArchive />} />
            {/* ================ Activities ===================== */}
            <Route path="/activities/categories" element={<ActivitiesCategories />} />
            <Route path="/activities/categories/:slug" element={<Activities />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/activate/:slug" element={<ActivateDetails />} />
            <Route path="/activities/add/:slug" element={<AddActivities />} />
            <Route path="/activities/:slug/edit" element={<EditActivities />} />
            {/* ================ Family Tree ===================== */}
            <Route path="/family-tree/add" element={<AddToFamilyTree />} />
            {/* ================ Votes ===================== */}
            <Route path="/votes/categories" element={<VotesCategories />} />
            <Route path="/votes/categories/:slug" element={<VoteInCat />} />
            <Route path="/votes/add/:slug" element={<AddNewVote />} />
            <Route path="/votes/edit/:slug" element={<EditVote />} />
            <Route path="/vote/:slug" element={<SingleVote />} />
            <Route path="/vote/notification" element={<VoteNotification />} />
            {/* ================================================== */}
            <Route path="/tech-support" element={<TechSupport />} />
            <Route path="/notification" element={<Notifications />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
      </Routes>
    </>
  );
}

export default App